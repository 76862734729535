import React, { lazy, Suspense } from 'react';

import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';

const FinancialReports = lazy(() => import('src/components/financial-reports/financial-reports'));

const isBrowser = typeof window !== 'undefined';

const FinancialReportsPage = () => {
  return (
    <Layout>
      <SEO title="Financial Reports" />
      <PrivateRoute>
        {isBrowser && (
          <Suspense fallback={<div />}>
            <FinancialReports />
          </Suspense>
        )}
      </PrivateRoute>
    </Layout>
  );
};

export default FinancialReportsPage;
